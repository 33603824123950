import { TextField } from "@mui/material";

const CustomTextField = (props) => {
  return (
    <TextField
      label={props.label}
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      onChange={(e) => {
        props.onChangeTextField(props.textFieldType, e);
      }}
      sx={{
        width: "100%",
      }}
      InputLabelProps={{
        style: {
          fontSize: "16px",
          color: "black",
        },
      }}
      InputProps={{
        sx: { height: "55px" },
        style: { fontSize: "2rem" },
      }}
      FormHelperTextProps={{
        style: {
          fontSize: "16px",
          color: "red",
        },
      }}
      required
      fullWidth
      variant="outlined"
    />
  );
};
export default CustomTextField;
