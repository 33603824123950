import axios from "axios";

export const callAPI = async (loginPayload, apiUrl) => {
  const response = await axios.post(
    `https://rkapi.rockets.com.my/livevs/rocketeer/${apiUrl}`,
    loginPayload
  );
  const data = response.data;

  return data;
};
