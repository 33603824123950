export const checkFiledIfEmpty = (
  variable,
  categoryType,
  category,
  warning
) => {
  return (
    variable.trim().length === 0 &&
    (categoryType === category ||
      categoryType === "checkAllField" ||
      warning !== "")
  );
};

export const checkFieldFormatIfInvalid = (
  variable,
  fieldType,
  categoryType,
  category,
  warning
) => {
  const nameformat = /^[0-9a-zA-Z ]+$/;
  const contactformat = /^[0-9]+$/;
  const mailformat = /\S+@\S+\.\S+/;
  const mailformat2 = /^[0-9a-zA-Z@._-]+$/;

  const checkingFieldStatus =
    categoryType === category ||
    categoryType === "checkAllField" ||
    warning !== "";

  if (fieldType === "name") {
    return !variable.match(nameformat) && checkingFieldStatus;
  } else if (fieldType === "contact") {
    return !variable.match(contactformat) && checkingFieldStatus;
  } else if (fieldType === "email") {
    return (
      (!variable.match(mailformat) || !variable.match(mailformat2)) &&
      checkingFieldStatus
    );
  }
};
