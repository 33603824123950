import rocketImage1 from "./img/rocket-1.png";
import rocketImage2 from "./img/rocket-2.png";
import rocketImage3 from "./img/rocket-3.png";
import rocketImage4 from "./img/rocket-4.png";
import rocketeeerSectionBackground from "./img/rocketeer-background.mp4";
import rocketeerImage1 from "./img/rocketeer-1.png";
import rocketeerImage2 from "./img/rocketeer-2.png";
import rocketeerImage3 from "./img/rocketeer-3.png";
import popcornImage from "./img/popcorn.png";
import rocketIcon from "./img/rocket_icon.png";
import rocketAppIcon from "./img/rocket-app-icon.png";
import rocketeerAppIcon from "./img/rocketeer-app-icon.png";
import appStoreIcon from "./img/app_store.png";
import playStoreIcon from "./img/play_store.png";
import appGalleryIcon from "./img/huawei_store.png";

import { useCallback, useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";

import CustomTextField from "./utils/CustomTextField";
import {
  checkFiledIfEmpty,
  checkFieldFormatIfInvalid,
} from "./utils/FieldChecking";
import { callAPI } from "./utils/CallAPI";

const App = () => {
  const [haveAnimate, setHaveAnimate] = useState({
    rocket1: false,
    rocket2: false,
    rocket3: false,
    rocket4: false,
    rocket5: false,
    rocket6: false,
    rocket7: false,
    rocket8: false,
    rocket9: false,
  });

  const y = useState(window.pageYOffset);

  useEffect(() => {
    const animationFunction = () => {
      // scrollY => the top of component
      // offsetTop => the top of component

      const componentArray = [
        "rocket1",
        "rocket2",
        "rocket3",
        "rocket4",
        "rocket5",
        "rocket6",
        "rocket7",
        "rocket8",
        "rocket9",
      ];

      const yDivisionArray = [100, 500, 500, 850, 850, 1250, 1250, 1650, 1650];
      for (let i = 0; i < componentArray.length; i++) {
        if (Number(window.scrollY) >= yDivisionArray[i]) {
          setHaveAnimate((prev) => {
            haveAnimate[componentArray[i]] = true;
            return { ...prev };
          });
        } else {
          setHaveAnimate((prev) => {
            haveAnimate[componentArray[i]] = false;
            return { ...prev };
          });
        }
      }
    };

    window.addEventListener("scroll", () => {
      animationFunction();
    });

    return () => {
      window.removeEventListener("scroll", () => {
        animationFunction();
      });
    };
  }, [y, haveAnimate]);

  const [disableButton, setDisableButton] = useState(false);
  const [fieldCategory, setFieldCategory] = useState("");
  const [captchaPass, setCaptchaPass] = useState(false);
  const captchaRef = useRef(null);

  const [name, setName] = useState("");
  const [dialCode, setDialCode] = useState("+60");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [interestingPackage, setInterestingPackage] = useState(
    "Premium (RM 1,200 / year)"
  );

  const [warning_Name, setWarning_Name] = useState("");
  const [warning_Contact, setWarning_Contact] = useState("");
  const [warning_Email, setWarning_Email] = useState("");

  const handleTextfieldChange = (type, e) => {
    setFieldCategory(type);

    type === "name" && setName(e.target.value);
    type === "dialcode" && setDialCode(e.target.value);
    type === "contact" && setContact(e.target.value);
    type === "email" && setEmail(e.target.value);
    type === "package" && setInterestingPackage(e.target.value);
  };

  const verifyInputData = () => {
    if (!disableButton) {
      setDisableButton(true);
      let isValid = true;

      const nameformat = /^[0-9a-zA-Z ]+$/;
      const contactformat = /^[0-9]+$/;
      const mailformat = /\S+@\S+\.\S+/;
      const mailformat2 = /^[0-9a-zA-Z@._-]+$/;

      if (
        name.trim().length === 0 ||
        !name.match(nameformat) ||
        contact.trim().length === 0 ||
        !contact.match(contactformat) ||
        email.trim().length === 0 ||
        !email.match(mailformat) ||
        !email.match(mailformat2) ||
        (contact !== "" &&
          warning_Contact ===
            "This contact has previously submitted a request") ||
        (email !== "" &&
          warning_Email === "This email has previously submitted a request")
      ) {
        isValid = false;
        fieldCheckingHandler("checkAllField");
      }

      if (!isValid) {
        setDisableButton(false);
      } else {
        if (captchaPass) {
          submitUserRequestFunction();
        } else {
          alert("Please finish ReCAPTCHA test");
          setDisableButton(false);
        }
      }
    }
  };

  const submitUserRequestFunction = async () => {
    const loginPayload = {
      name: name,
      contact: dialCode + contact,
      email: email,
      interest_package: interestingPackage,
    };
    const data = await callAPI(loginPayload, "landingPage/submitUserRequest");

    if (data[0] === "duplicate") {
      data[1] &&
        setWarning_Contact("This contact has previously submitted a request");
      data[2] &&
        setWarning_Email("This email has previously submitted a request");
    } else if (data[0] === "Success") {
      alert("Thank You! Your interest has successfully sent to us.");
      closeFormSubmission();
      captchaRef.current.reset();
    } else {
      alert("Failed to send your request, please try again later");
      captchaRef.current.reset();
    }
    setDisableButton(false);
  };

  const fieldCheckingHandler = useCallback(
    (category) => {
      if (category !== "checkAllField") {
        category = fieldCategory;
      }

      checkFiledIfEmpty(name, category, "name", warning_Name)
        ? setWarning_Name("Your name is required for record")
        : checkFieldFormatIfInvalid(
            name,
            "name",
            category,
            "name",
            warning_Name
          )
        ? setWarning_Name(
            "Please enter your name with characters, numbers and space ONLY"
          )
        : setWarning_Name("");

      checkFiledIfEmpty(contact, category, "contact", warning_Contact)
        ? setWarning_Contact("Your contact is required for record")
        : checkFieldFormatIfInvalid(
            contact,
            "contact",
            category,
            "contact",
            warning_Contact
          )
        ? setWarning_Contact("Please enter your contact with number ONLY")
        : contact !== "" &&
          warning_Contact === "This contact has previously submitted a request"
        ? setWarning_Contact("This contact has previously submitted a request")
        : setWarning_Contact("");

      checkFiledIfEmpty(email, category, "email", warning_Email)
        ? setWarning_Email("Your email is required for record")
        : checkFieldFormatIfInvalid(
            email,
            "email",
            category,
            "email",
            warning_Email
          )
        ? setWarning_Email("Please enter your email with correct email format")
        : email !== "" &&
          warning_Email === "This email has previously submitted a request"
        ? setWarning_Email("This email has previously submitted a request")
        : setWarning_Email("");
    },
    [
      name,
      contact,
      email,
      warning_Name,
      warning_Contact,
      warning_Email,
      fieldCategory,
    ]
  );

  useEffect(() => {
    const identifier = setTimeout(() => {
      fieldCheckingHandler();
    }, 500);

    return () => {
      clearTimeout(identifier);
    };
  }, [fieldCheckingHandler]);

  const checkContactOREmailRepeat = useCallback(async () => {
    if (contact !== "") {
      const loginPayload = {
        type: "contact",
        contact: dialCode + contact,
        email: "",
      };
      const data = await callAPI(
        loginPayload,
        "landingPage/checkContactOREmailRepeat"
      );

      if (data[0] === "This contact has been used") {
        setWarning_Contact("This contact has previously submitted a request");
      } else {
        setWarning_Contact("");
      }
    }
    if (email !== "") {
      const loginPayload = {
        type: "email",
        contact: "",
        email: email,
      };
      const data = await callAPI(
        loginPayload,
        "landingPage/checkContactOREmailRepeat"
      );

      if (data[0] === "This email has been used") {
        setWarning_Email("This email has previously submitted a request");
      } else {
        setWarning_Email("");
      }
    }
  }, [dialCode, contact, email]);

  useEffect(() => {
    const identifier = setTimeout(() => {
      checkContactOREmailRepeat();
    }, 3000);

    return () => {
      clearTimeout(identifier);
    };
  }, [checkContactOREmailRepeat]);

  const closeFormSubmission = () => {
    setDisableButton(false);
    setFieldCategory("");
    setName("");
    setDialCode("+60");
    setContact("");
    setEmail("");
    setInterestingPackage("Premium (RM 1,200 / year)");
    setWarning_Name("");
    setWarning_Contact("");
    setWarning_Email("");
    setCaptchaPass(false);
  };

  const recaptchaFunctionValidate = async () => {
    const token = captchaRef.current.getValue();
    const loginPayload = {
      recaptcha_token: token,
    };
    const data = await callAPI(loginPayload, "landingPage/checkReCAPTCHA");

    if (data[0] === "Human") {
      setCaptchaPass(true);
    } else {
      setCaptchaPass(false);
    }
  };

  return (
    <div className="body-container">
      <header class="header-container">
        <a href="#home">
          <img class="rocket-logo" src={rocketIcon} alt="Rocket logo" />
        </a>

        <ul class="navigation-list">
          <li>
            <a class="navigation-link" href="#rocket">
              Rocket-Users
            </a>
          </li>
          <li>
            <a class="navigation-link" href="#rocketeer">
              Rocketeer-Merchants
            </a>
          </li>
          <li>
            <a class="navigation-link" href="#pricing">
              Pricing
            </a>
          </li>
          <li>
            <a class="navigation-link" href="#contact">
              Contact Us
            </a>
          </li>
        </ul>
      </header>

      <section className="home-container" id="home">
        <h1 className="heading-1">
          <span className={`heading-1--main`}>Rocket</span>
          <span className="heading-1--sub">
            Your trusty
            <span style={{ color: "#383838" }}> loyalty program </span>
            companion
          </span>
        </h1>

        <a className="btn-1" href="#rocket">
          Discover Now!
        </a>
      </section>

      <section id="rocket">
        <h2
          className={`heading-2 ${
            haveAnimate.rocket1 ? "animation-start-moveInFromTop" : ""
          }`}
          id="rocket1"
        >
          What's special in User's Rocket App
        </h2>

        <div className="rocket-container">
          <div className="rocket-img-container rocket-img-container-position">
            <img
              className={`rocket-img-container--size ${
                haveAnimate.rocket2 ? "animation-start-moveInFromLeft" : ""
              }`}
              src={rocketImage1}
              alt="rocket estamp ui"
              id="rocket2"
            />
          </div>

          <div
            className={`rocket-text-box rocket-text-box-position ${
              haveAnimate.rocket3 ? "animation-start-moveInFromBottom" : ""
            }`}
            id="rocket3"
          >
            <h3 className="rocket-text-box--title ">Point & Stamp & Code</h3>
            <p className="rocket-text-box--description">
              Never think accumulation is hard! Accumulate points and stamps
              from different merchants or just use promo codes to redeem
              vouchers.
            </p>
          </div>
        </div>

        <div className="rocket-container">
          <div
            className={`rocket-text-box rocket-text-box-position  ${
              haveAnimate.rocket4 ? "animation-start-moveInFromTop" : ""
            }`}
            id="rocket4"
          >
            <h3 className="rocket-text-box--title">Receive & Use Voucher</h3>
            <p className="rocket-text-box--description">
              Get vouchers when you meet the conditions and never forget to use
              it before expires.
            </p>
          </div>
          <div className="rocket-img-container rocket-img-container-position">
            <img
              className={`rocket-img-container--size ${
                haveAnimate.rocket5 ? "animation-start-moveInFromRight" : ""
              }`}
              src={rocketImage2}
              alt="rocket voucher list"
              id="rocket5"
            />
          </div>
        </div>

        <div className="rocket-container">
          <div className="rocket-img-container rocket-img-container-position">
            <img
              className={`rocket-img-container--size ${
                haveAnimate.rocket6 ? "animation-start-moveInFromLeft" : ""
              }`}
              src={rocketImage3}
              alt="rocket qr code and bar code"
              id="rocket6"
            />
          </div>
          <div
            className={`rocket-text-box rocket-text-box-position  ${
              haveAnimate.rocket7 ? "animation-start-moveInFromTop" : ""
            }`}
            id="rocket7"
          >
            <h3 className="rocket-text-box--title">QR & Bar Code</h3>
            <p className="rocket-text-box--description">
              Always the easier way to show your ID to the cashier for making
              transactions, sign up as a merchant member and more...
            </p>
          </div>
        </div>

        <div className="rocket-container">
          <div
            className={`rocket-text-box rocket-text-box-position  ${
              haveAnimate.rocket8 ? "animation-start-moveInFromBottom" : ""
            }`}
            id="rocket8"
          >
            <h3 className="rocket-text-box--title">Outlet List & Details</h3>
            <p className="rocket-text-box--description">
              Outlet listing will become your favorite way to explore the
              merchants you want to shop and of course check out the vouchers
              offered by them.
            </p>
          </div>
          <div className="rocket-img-container rocket-img-container-position">
            <img
              className={`rocket-img-container--size ${
                haveAnimate.rocket9 ? "animation-start-moveInFromRight" : ""
              }`}
              src={rocketImage4}
              alt="rocket merchant outlet list"
              id="rocket9"
            />
          </div>
        </div>
      </section>

      <section className="rocketeer-section" id="rocketeer">
        <div className="rocketeer-section--background">
          <video
            className="rocketeer-section--background-content"
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={rocketeeerSectionBackground} type="video/mp4" />
            {/* <source src="img/video.webm" type="video/webm" /> */}
          </video>
        </div>

        <h2 className="heading-2 margin-top-3rem">
          A great loyalty program for merchants
        </h2>

        <div className="rocketeer-container">
          <div className="rocketeer-img-box rocketeer-img-box-position">
            <img
              src={rocketeerImage1}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="rocketeer merchant outlet list"
              className={`rocketeer-img-box--image rocketeer-img-box--image-i1`}
            />

            <img
              src={rocketeerImage2}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="rocketeer merchant outlet list"
              className={`rocketeer-img-box--image rocketeer-img-box--image-i2`}
            />

            <img
              src={rocketeerImage3}
              sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
              alt="rocketeer merchant outlet list"
              className={`rocketeer-img-box--image rocketeer-img-box--image-i3`}
            />
          </div>

          <div className="rocketeer-text-box rocketeer-text-box-position">
            <h3 className="rocketeer-heading">
              Voucher = Your Settings + Member's efforts
            </h3>
            <p className="rocketeer-description">
              Use whatever method you like to set up merchant's vouchers through
              point, stamp, promo code or various campaigns. If your voucher is
              nice to have, members will always fight for it.
            </p>

            <h3 className="rocketeer-heading">Credit! Credit! Credit!</h3>
            <p className="rocketeer-description">
              Do you want members to top up credit before spending? Rocket will
              let you get what you want! No need to worry about how to handle
              member credits anymore.
            </p>

            <h3 className="rocketeer-heading">
              Reporting~ Listing~ Understanding~
            </h3>
            <p className="rocketeer-description">
              A series of reports always is the key to understanding your
              business. The report with filter function allows you to check only
              what you want to see. The more detailed listing, the more you will
              know.
            </p>
          </div>
        </div>
      </section>

      <section id="pricing">
        <h2 className="heading-2">Our Pricing Package</h2>

        <div className="pricing-container">
          {/* box 1 start */}
          <div className="pricing-card-box pricing-card-box-position--1">
            <div className="card card-front">
              <div className="card-front-header">
                <div className="card-front-image card-front-image--i1" />
                <h3 className="card-front-title-box">
                  <span className="card-front-title-box-content card-front-title-box-content--1">
                    Basic Plan
                  </span>
                </h3>
              </div>

              <div className="card-front-details">
                <ul>
                  <li>Voucher</li>
                  <li>Point / Stamp</li>
                  <li>Credit</li>
                  <li>Reporting</li>
                  <li>Transaction</li>
                  <li>1 Outlet</li>
                </ul>
              </div>
            </div>

            <div className="card card-back card-back--1">
              <div className="card-back--price-box">
                <p className="card-back--price-box-only">Only</p>
                <p className="card-back--price-box-value">RM 1,000</p>
                <p className="card-back--price-box-only">per yaer</p>
              </div>
              <div className="card-back--price-box-button-box">
                <a
                  className="card-back--price-box-button-box--btn"
                  href="#contact"
                  onClick={() => {
                    setInterestingPackage("Basic (RM 1,000 / year)");
                  }}
                >
                  Subscribe now!
                </a>
              </div>
            </div>
          </div>
          {/* box 1 end */}

          {/* box 2 start */}
          <div className="pricing-card-box pricing-card-box-position--2">
            <div className="card card-front">
              <div className="card-front-header">
                <div className="card-front-image card-front-image--i2" />
                <h3 className="card-front-title-box">
                  <span className="card-front-title-box-content card-front-title-box-content--1">
                    Premium Plan
                  </span>
                </h3>
              </div>

              <div className="card-front-details">
                <ul>
                  <li>Voucher</li>
                  <li>Point / Stamp</li>
                  <li>Credit</li>
                  <li>Reporting</li>
                  <li>Transaction</li>
                  <li>1 Outlet</li>
                  <li>Campaign</li>
                </ul>
              </div>
            </div>

            <div className="card card-back card-back--1">
              <div className="card-back--price-box">
                <p className="card-back--price-box-only">Only</p>
                <p className="card-back--price-box-value">RM 1,200</p>
                <p className="card-back--price-box-only">per yaer</p>
              </div>
              <div className="card-back--price-box-button-box">
                <a
                  className="card-back--price-box-button-box--btn"
                  href="#contact"
                  onClick={() => {
                    setInterestingPackage("Premium (RM 1,200 / year)");
                  }}
                >
                  Subscribe now!
                </a>
              </div>
            </div>
          </div>
          {/* box 2 end */}
        </div>

        <div className="integration-box">
          <h3 className="heading-2">Integration Partner</h3>
          <img src={popcornImage} className="popcornImage" alt="popcorn icon" />
        </div>
      </section>

      <section className="section-contactus" id="contact">
        <h3 className="contactus-heading">Contact Us</h3>

        <div className="contactus-container">
          <div className="contactus-form">
            <div className="contactus-form-position--1">
              <CustomTextField
                textFieldType="name"
                label="Name"
                value={name}
                error={warning_Name !== ""}
                helperText={warning_Name}
                onChangeTextField={handleTextfieldChange}
              />
            </div>

            <div className="contactnumber-box contactus-form-position--2">
              <FormControl className="contactnumber-box--code">
                <InputLabel
                  style={{ fontSize: "16px", color: "black" }}
                  required
                >
                  Dial Code
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={dialCode}
                  label="Dial Code"
                  style={{ height: "55px", fontSize: "2rem" }}
                  onChange={(e) => {
                    handleTextfieldChange("dialcode", e);
                  }}
                >
                  <MenuItem sx={{ fontSize: "16px" }} value="+60">
                    +60
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "16px" }} value="+65">
                    +65
                  </MenuItem>
                  <MenuItem sx={{ fontSize: "16px" }} value="+62">
                    +62
                  </MenuItem>
                </Select>
              </FormControl>

              <CustomTextField
                className="contactnumber-box--number"
                textFieldType="contact"
                label="Contact Number"
                value={contact}
                error={warning_Contact !== ""}
                helperText={warning_Contact}
                onChangeTextField={handleTextfieldChange}
              />
            </div>
          </div>

          <div className="contactus-form">
            <div className="contactus-form-position--1">
              <CustomTextField
                textFieldType="email"
                label="Email"
                value={email}
                error={warning_Email !== ""}
                helperText={warning_Email}
                onChangeTextField={handleTextfieldChange}
              />
            </div>

            <div className="contactus-form-position--2">
              <FormControl fullWidth>
                <InputLabel
                  style={{ fontSize: "16px", color: "black" }}
                  required
                >
                  Interesting Package
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={interestingPackage}
                  label="Interesting Package"
                  style={{ height: "55px", fontSize: "20px" }}
                  onChange={(e) => {
                    handleTextfieldChange("package", e);
                  }}
                >
                  <MenuItem
                    sx={{ fontSize: "16px" }}
                    value="Basic (RM 1,000 / year)"
                  >
                    Basic (RM 1,000 / year)
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "16px" }}
                    value="Premium (RM 1,200 / year)"
                  >
                    Premium (RM 1,200 / year)
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div className="contactus-form">
            <div className="item-rightsize contactus-form-position--1">
              <ReCAPTCHA
                sitekey="6LfyXH4lAAAAAOBIUR2D0E2gW37ZorUsvtuaQMNA"
                ref={captchaRef}
                onChange={recaptchaFunctionValidate}
              />
            </div>

            <div className="item-vercenter contactus-form-position--2">
              <button className="submit-btn" onClick={verifyInputData}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-container">
        <div className="rocket-icon">
          <a href="https://rockets.com.my" target="_blank" rel="noreferrer">
            <img
              src={rocketIcon}
              className="rocket-icon-size"
              alt="rocket icon"
            />
          </a>
        </div>

        <div className="appPlusPartner-container">
          <div className="theree-platfrom-app-container theree-platfrom-app-container-position">
            <div className="store-container">
              <img
                src={appStoreIcon}
                className="store-container-size"
                alt="Apple App Store"
              />

              <div className="app-container">
                <a
                  href="https://apps.apple.com/us/app/rocket-membership/id1639869061"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketAppIcon}
                    className="app-container-size"
                    alt="Rocket App"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/rocketeer-membership/id1635107647"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketeerAppIcon}
                    className="app-container-size"
                    alt="Rocketeer App"
                  />
                </a>
              </div>
            </div>

            <div className="store-container">
              <img
                src={playStoreIcon}
                className="store-container-size"
                alt="Google Play Store"
              />

              <div className="app-container">
                <a
                  href="https://play.google.com/store/apps/details?id=com.rockbelldev.rocket"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketAppIcon}
                    className="app-container-size"
                    alt="Rocket App"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.rockbelldev.rocketeer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketeerAppIcon}
                    className="app-container-size"
                    alt="Rocketeer App"
                  />
                </a>
              </div>
            </div>

            <div className="store-container">
              <img
                src={appGalleryIcon}
                className="store-container-size"
                alt="Huawei App Gallery"
              />

              <div className="app-container">
                <a
                  href="https://appgallery.huawei.com/app/C106889557"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketAppIcon}
                    className="app-container-size"
                    alt="Rocket App"
                  />
                </a>
                <a
                  href="https://appgallery.huawei.com/app/C106714865"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={rocketeerAppIcon}
                    className="app-container-size"
                    alt="Rocketeer App"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="company-container company-container-position--1">
            <h3 className="footer-sub-title">Company</h3>
            <ul className="company-list">
              <a
                className="company-list-item-click"
                href="https://rockbell.com.my/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="company-list-item">Rockbell Software SDN BHD</li>
              </a>
              <a
                className="company-list-item-click"
                href="https://popcorntek.com.my/"
                target="_blank"
                rel="noreferrer"
              >
                <li className="company-list-item">Partner - Popcorn</li>
              </a>
            </ul>
          </div>

          <div className="company-container company-container-position--2">
            <h3 className="footer-sub-title">Find Us</h3>
            <ul className="company-list">
              <li>+607-2413172</li>
              <li>dev2rockbell@gmail.com</li>
              <li>
                7A, Jalan Undan15, Taman Perling, 81200 Johor Bahru, Johor,
                Malaysia
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-copyright-container">
          <h3 className="footer-copyright-container-title">
            Copyright &copy; by Rockbell Software SDN BHD. All Right Reserved.
          </h3>
        </div>
      </footer>
    </div>
  );
};

export default App;
